//-------------
// Ebook Page
//-------------

// PACKAGES
import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import IdentityModal, {
    useIdentityContext,
  } from "react-netlify-identity-widget"

// COMPONENTS
import Logo from "../assets/logos/ie-base-code.svg"
import MenuBack from "../assets/icons/menu-back.svg"

// COMPONENT BUILD
const EbookPage = () => {
    // DATA QUERY
    const data = useStaticQuery(graphql`
        query {
            allMarkdownRemark(
                sort: { fields: [frontmatter___date], order: ASC }
                filter: { frontmatter: { type: { eq: "ebook" } } }
            ) {
                edges {
                    node {
                        frontmatter {
                            type
                            title
                            date(formatString: "YYYY.MMMM.DD, dddd")
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `)

    const identity = useIdentityContext()
    const [dialog, setDialog] = React.useState(false)
    const name = (identity && identity.user && identity.user.user_metadata && identity.user.user_metadata.full_name) || 'Staff'

    // console.log(JSON.stringify(identity))
    const isLoggedIn = identity && identity.isLoggedIn

    // JSX TEMPLATE
    return (
        <div>
            <Helmet defer={false} defaultTitle='Informeasy | House manual booklet for vacation rental'>
                <html lang='en' />
                <meta name="viewport" content="initial-scale=1, shrink-to-fit=no, user-scalable=no, width=device-width"/>
                <meta name="robots" content="noindex" />
                <noscript>Please eneable Javascript or use a modern web browser!</noscript>
            </Helmet> 
            <nav className="navbar px-4 fixed-top navbar-expand-md navbar-light bg-white border-bottom border-ie-lighter py-2 align-self-autopy-md-3">
                        <div className="container">
                            <Link to="/">
                                <img
                                    className="logo-size"
                                    src={Logo}
                                    alt="Informeasy"
                                />
                            </Link>
                            <button
                                className="navbar-toggler border-0 px-2 py-1"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navigations-08"
                                aria-controls="navigations-08"
                                aria-expanded="false"
                                aria-label="Toggle menu">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div
                                className="collapse navbar-collapse"
                                id="navigations-08">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link menu-link mr-md-5 py-6 py-md-2"
                                            to="/">
                                            <img
                                                className="d-inline-block position-relative pb-1 mr-3"
                                                src={MenuBack}
                                                alt="Arrow-icon"
                                            />
                                            Visit homepage
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <button className="btn btn-primary text-white" onClick={() => setDialog(true)}>
                                            {isLoggedIn ? `Hello ${name}, Log out here!` : "LOG IN"}
                                        </button>
                                        <IdentityModal
                                            showDialog={dialog}
                                            onCloseDialog={() => setDialog(false)}
                                        />
                                    </li>
                                </ul>
                            </div> 
                        </div>
                    </nav>
                    <section className="pt-5 pb-7 pb-lg-10">

                { isLoggedIn ? (
                <section className="container px-4 text-center mb-n11 text-center pt-8 pt-md-10">
                    <h2 className="mb-8 mb-lg-10 text-center underline">
                        <h1>Ebooks</h1>    
                    </h2>
                    <div className="text-left pb-8">
                        <ul style={{listStyleType: "none", margin: "0", padding: "0"}}>
                            {data.allMarkdownRemark.edges.map(edge => {
                                return (
                                    <li className="border bg-white mb-4 p-4">
                                        <Link to={`/ebook/${edge.node.fields.slug}`}>
                                            <h4 className="header-sans">{edge.node.frontmatter.title}</h4>
                                            <p className="mb-0">{edge.node.frontmatter.date}</p>
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </section>
                ) : 
                (
                <section className="container px-4 text-center mb-n11 text-center pt-8 pt-md-10">
                    <h2 className="mb-6 text-center underline">
                        This page is protected.
                    </h2>
                    <p>
                        This page is password protected. If you are the website admin, <br/> or have access to this page, please login in.
                    </p>
                </section>
                )}
            </section>
        </div>
    )
}

export default EbookPage
